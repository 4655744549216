import React, { Component } from 'react'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
class Disclosure extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (<React.Fragment>
            <main>
                <Container maxWidth="md">
                    <Grid item xs={12}>
                        <Typography component="h5" variant="h5" align="left" color="textPrimary" gutterBottom>
                            Disclosure Policy
            </Typography>
                        <p style={{ fontSize: '1.20em' }}>This blog is a digital product of <a href="http://www.ttstrategies.com" rel="noopener" target="_blank">TT Strategies Inc</a>. This is a collaborative blog written by a group of individuals. For questions about this blog, please reach us at contact@sapaadu.com. </p>
                        <p style={{ fontSize: '1.20em' }}>There is a cost to create content, maintain and protect the site from malicious attacks. We try to offset these costs and other expenses by using forms of advertising, sponsorship, paid insertions, or other forms of compensation. Any links on this site are either ads or affiliate links. If you click on ads, we may get a small compensation for the traffic we are forwarding them. If you click on affiliate links and in case you end up buying products from affiliates, then we get a small commission from your purchase. We appreciate your support!. </p>
                        <p style={{ fontSize: '1.20em' }}>This blog abides by word of mouth marketing standards. We believe in honesty of relationship, opinion, and identity. The compensation received may influence the advertising content, topics, or posts made in this blog. That content, advertising space, or post will be clearly identified as paid or sponsored content.  </p>
                        <p style={{ fontSize: '1.20em' }}>The owner(s) of this blog is not compensated to provide opinion on products, services, websites, and various other topics. The views and opinions expressed on this blog are purely the blog owners. If we claim or appear to be experts on a certain topic or product or service area, we will only endorse products or services that we believe, based on our expertise, are worthy of such endorsement. Any product claim, statistic, quote, or other representation about a product or service should be verified with the manufacturer or provider.  </p>
                        <p style={{ fontSize: '1.20em' }}>Any content presented here is not intended and should not be substituted for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician, or your health care provider regarding any medical advice, symptoms, or any medical condition. Do not delay or disregard any professional medical advice based on anything you have read on this site.  </p>
                        <p style={{ fontSize: '1.20em' }}>This site is for educational purposes only. No medical advice is provided here. Any information found on this site is to encourage you to make your own decision about health care and diet by basing on your own research, and in association along with guidance from your health care provider. </p>
                        <Typography component="h5" variant="h5" align="left" color="textPrimary" gutterBottom>
                            Health Tips Disclaimer
            </Typography>
                        <p style={{ fontSize: '1.20em' }}>Any claims about possible health benefits, of any food products, ingredients, supplements etc., mentioned here are not tested and/or certified by the Food and Drug Administration. </p>
                        <p style={{ fontSize: '1.20em' }}>Any claims about possible health benefits, of any food products, ingredients, supplements etc., mentioned here are not intended to diagnose, treat, cure or prevent any disease. </p>
                        <p style={{ fontSize: '1.20em' }}>By using this site, you agree to abide by these Disclosure and Disclaimer terms. Terms subject to change at any time.
 </p>
                        <p style={{ fontSize: '1.20em' }}>Any claims about possible health benefits, of any food products, ingredients, supplements etc., mentioned here are not tested and/or certified by the Food and Drug Administration. </p>
                        <p style={{ fontSize: '1.20em' }}>To get your own policy, go to <a href="http://www.disclosurepolicy.org" target="_blank"><img src="/wp-content/uploads/2017/10/badge-small.jpg" alt="" width="88" height="31" class="alignnone size-full wp-image-2474" /></a>   </p>
                    </Grid></Container></main></React.Fragment>);
    }
}

export default Disclosure;
