import React, { Component } from 'react'
// import { Container, Col, Row, Card, CardBody, CardTitle, Input  } from 'reactstrap';
import Pagination from './Pagination';
import ReadMoreAndLess from 'react-read-more-less';
import ToggleText from './ToggleText '
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { Dropdown } from 'semantic-ui-react'
import Paper from '@material-ui/core/Paper';
import { Breadcrumb } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown'

// import classes from '*.module.css';
import GrandmaView from './GrandmaView'



const styles = theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    }, paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }
});
class GranmaSays extends Component {
    constructor() {
        super()
        var exampleItems = [...Array(260).keys()].map(i => i);
        this.state = {
            SearchUrl: `${process.env.REACT_APP_BACKEND_URL}`,
            Ingredient: [],
            exampleItems: exampleItems,
            pageOfItems: [],
            Current_Search: "A",
            Url: '/grama-says?IngredientName_containss=',
            FirstSearch: '/grama-says?',
            Sort: '&_sort=IngredientName:ASC',
            Join_url: '&IngredientName_containss=',
            RandomFirstId: 'id_in=',
            RandomSecondid: '&id_in=',
            readMore: false,
            SearchFlag: 1,
            original_Ingredients: [],
            SelectionCount: 0,
            RandomQuerys:null
        }
        this.onChangePage = this.onChangePage.bind(this);
        this.handleChange = this.handleChange.bind(this);


    }

    componentDidMount() {
        let RandomQuery = '';
        var nums = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            ranNums = [],
            i = nums.length,
            j = 0;

        while (i--) {
            j = Math.floor(Math.random() * (i + 1));
            ranNums.push(nums[j]);
            if (ranNums.length > 1) {
                RandomQuery += this.state.RandomSecondid + nums[j]
            } else {
                RandomQuery = this.state.RandomFirstId + nums[j]
            }
            nums.splice(j, 1);

        }
        let Direct_Query = this.state.SearchUrl + this.state.FirstSearch + RandomQuery
        console.log(Direct_Query)
        

        this.fetchData(Direct_Query)
        Promise.all([
            fetch(this.state.SearchUrl + '/grama-says').then(res => res.json()),

        ]).then(result => {
            var temp = [];
            result[0].forEach(element => {
                var obj = {}; obj = { key: element.IngredientName, text: element.IngredientName, value: element.IngredientName }
                temp.push(obj)
            });
            this.setState({
                original_Ingredients: temp,
                RandomQuerys: this.state.FirstSearch + RandomQuery
            })
        });

        try { window._mNHandle.queue.push(function (){ window._mNDetails.loadTag("864068883", "970x250", "864068883"); }) } catch (error) {} 
        try { window._mNHandle.queue.push(function (){ window._mNDetails.loadTag("211085901", "970x90", "211085901"); }); } catch (error) {}
        try { window._mNHandle.queue.push(function (){ window._mNDetails.loadTag("575518156", "728x90", "575518156"); }); } catch (error) {}
        try { window._mNHandle.queue.push(function (){ window._mNDetails.loadTag("199144025", "320x50", "199144025"); }); } catch (error) {} 
        try { window._mNHandle.queue.push(function (){ window._mNDetails.loadTag("108569473", "300x600", "108569473"); }); } catch (error) {} 
        try { window._mNHandle.queue.push(function (){ window._mNDetails.loadTag("759031059", "300x50", "759031059"); }); } catch (error) {} 
        try { window._mNHandle.queue.push(function (){ window._mNDetails.loadTag("828944828", "160x600", "828944828"); }); } catch (error) {} 

    }

    onChangePage = (event) => {
        console.log((event[0] / 10) + 1)
        let page = ((event[0] / 10) + 1)
        let C_page = ((page + 9).toString(36).toUpperCase());
        this.setState({ Current_Search: C_page, SearchFlag: 1, SelectionCount: this.state.SelectionCount + 1 });
        let current_Url = this.state.SearchUrl + this.state.Url + C_page;
        this.fetchData(current_Url)
    }
    fetchData(Search_URL) {
        console.log(Search_URL)
        let Conway = [];
        try {
            fetch(Search_URL)
                .then(function (response) {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    return response.json();
                })
                .then((res) => {
                    console.log(res)



                    console.log('com', this.state.SearchFlag)
                    if (this.state.SearchFlag == 1 && this.state.SelectionCount > 1) {
                        res.map((item) => {
                            // alert('come1')
                            if (item.IngredientName[0] == this.state.Current_Search) {
                                Conway.push(item)
                            }
                        })
                    } else {
                        res.map((items) => {
                            Conway.push(items)
                        })
                    }


                    console.log('dsa', Conway)
                    this.setState({
                        Ingredient: Conway
                    })
                });
        } catch (error) {
            alert("Something Went Wrong");
            console.log(error);
        }
    }
    GranmaSayPost = (event) => {
        const history = this.props.history
        console.log(history)
        if (history) history.push(`/GrandmaSays/${event.target.dataset.value}`)
    }
    onChangeSearch = (e) => {

        console.log('ji');

    }
    handleChange = (e, { value }) => {
        this.setState({
            SearchFlag: 0
        })
        let searchItem = '';
        if(value.length>0){
            value.forEach((element, i) => {
            

                if (i > 0) {
                    searchItem += this.state.Join_url + element
                } else {
                    searchItem += this.state.Url + element
                }
    
            });
        }else{
            searchItem=this.state.RandomQuerys
        }
       
        console.log(searchItem)
        console.log(this.state.SearchUrl)
        this.fetchData(this.state.SearchUrl + searchItem)

    }


    render() {
        console.log(this.state.original_Ingredients)
        const { classes } = this.props;
        let itemList;

        if (this.state.Ingredient == undefined) {
            console.log("empty")
        } else {
            console.log("non-empty")
            itemList = this.state.Ingredient.map((item, i) => {
                return (<Grid item key={i} xs={12} sm={6} md={4}>
                    <Card className={classes.card}>
                        {item.FeatureImage != null ? <CardMedia
                            className={classes.cardMedia, 'post_thumb_withbg'}
                            image={this.state.SearchUrl + item.FeatureImage.url}
                            title= {item.IngredientName}
                        > <a className='post_thumb' onClick={this.GranmaSayPost} data-value={item.IngredientName}></a></CardMedia> : ""}

                        <CardContent className={classes.cardContent,'ReceipeItem'} style={{ padding: '10px' }}>
                            <Typography gutterBottom variant="h5" component="h2" align='center' className="Anchor" onClick={this.GranmaSayPost} data-value={item.IngredientName} >
                                {item.IngredientName}
                            </Typography>
                            {/* <Typography>
                                This is a media card. You can use this section to describe the content.
                                </Typography> */}
                            {/* <ToggleText {...item} /> */}

                        </CardContent>
                        {/* <CardActions> */}
                        {/* <Button size="small" color="primary">
                                View
                                </Button>
                            <Button size="small" color="primary">
                                Edit
                                </Button> */}
                        {/* </CardActions> */}
                    </Card>
                </Grid>)

            })

        }

        return (
            <React.Fragment>
                <main>
                    <Container className={classes.cardGrid} maxWidth="md">
                        <Grid item xs={12}>
                        <Breadcrumb>
    <Breadcrumb.Section link><a href='/'>Home</a></Breadcrumb.Section>
    <Breadcrumb.Divider icon='right angle' />
    <Breadcrumb.Section link>GrandmaSays</Breadcrumb.Section>
   
   
  </Breadcrumb>
                            <Grid item xs={12}>
                                <p></p>
                                <p style={{ fontSize: '1.33em' }}>"Grandma Says" is our new space on <a > SAPAADU.COM, </a> dedicated to all the wise "foody" advice received from our parents, grandparents, great-grandparents, and all our ancestors. </p>
                                <p style={{ fontSize: '1.33em' }}>There is indeed no limit to the wisdom of our ancestors, without whom we have no identity, culture, or customs. It is believed that our ancestors in India lead a life with deeper meaning and purpose. They tailored their day to day habits as well as positioned every meal, food item, or ingredient with a greater goal of leading a healthy life. Many such said or unsaid customs continue to protect our health today. Our ancestors possessed deep knowledge and a superior understanding of various medicinal abilities that certain food products possess. Even though most of these are beyond our knowledge or research abilities to understand or prove, we see that in our day to day life "food as a medicine" has cured some common illnesses. To believe them or not is up to the individual. </p>
                                <p style={{ fontSize: '1.33em' }}>In honor of our ancestors and their knowledge, we are dedicating this segment "Grandma Says" to all those beliefs our ancestors carried. We are going to list down some of these abilities of Indian foods, as well as ingredients here. This knowledge is mostly common knowledge that is carried on through generations, and we are just trying to do our part to preserve and share these with everyone interested.</p>
                                <p style={{ fontSize: '1.33em' }}>Check out our <a href="/Disclosure">disclosure & disclaimer </a> before proceeding ahead. Hope you read that. Good!, now that we got that out of the way, we are excited to fill this space with some interesting info. Keep an eye on this space or sign up for our email newsletter to stay in the loop. </p>



                            </Grid>
                            <Grid item xs>
                            </Grid>
                        </Grid>
                        <div id="211085901"></div>
                    </Container>
                    <Grid container spacing={3}>
                        <Grid item xs>

                        </Grid>
                        <Grid item xs={10} sm={6}>
                            <Dropdown
                                placeholder='Check what Grandma Says!'
                                fluid
                                multiple
                                search
                                selection
                                options={this.state.original_Ingredients}
                                onChange={this.handleChange}

                            />
                        </Grid>
                        <Grid item xs>
                        </Grid>
                    </Grid>

                    {/* <Input onChange={this.onChangeSearch} placeholder='Search Ingredients'/> */}
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                        <Pagination items={this.state.exampleItems} onChangePage={this.onChangePage} />

                        </Grid></Grid>
                    <Container className={classes.cardGrid} maxWidth="md">
                        {/* End hero unit */}
                        <Grid container spacing={4}>
                            {itemList}
                        </Grid>
                    </Container>

                </main>

            </React.Fragment>
        )
    }
}

export default withStyles(styles, { withTheme: true })(GranmaSays);