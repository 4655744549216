import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import {Helmet} from "react-helmet";
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
        cursor: 'pointer'
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    chip:{
        padding:"20px"
    }
});
class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    
    render() { 
        let {classes}=this.props
        return ( 
    <React.Fragment>
    <footer >
    <Typography variant="body2" color="textSecondary" align="center">

<Link color="inherit" href="/Disclosure">
Disclosure Policy
</Link>{' '}

{'.'}
</Typography>
<Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="http://sapaadu.com">
        sapaadu.com
  </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
    </Typography>
    
{/* <h1>Ad Code: 1 - 160 x 600 - (Wide Skyscraper) </h1><div id="828944828"></div>
<h1> Ad Code 2 - 300 x 50 - (Smartphone Banner) </h1>  <div id="759031059"></div>
<h1>Ad Code 3 - 300 x 250 - (Medium Rectangle) </h1> <div id="723115345"></div>
<h1>Ad Code 4 - 300 x 600 - (Half Page Ad) </h1><div id="108569473"></div>
<h1>Ad Code 5 - 320 x 50 - (Smartphone Banner)  </h1>  <div id="199144025"></div>
<h1>Ad Code 6 - 728 x 90 - (Leaderboard)  </h1>  <div id="575518156"></div>
<h1>Ad Code 7 - 970 x 90 - (Super Leaderboard)  </h1>  <div id="211085901"></div>
<h1>Ad Code 8 - 970 x 250 - (Billboard)  </h1>  <div id="864068883"></div> */}
    
    
    </footer>
    </React.Fragment>
    );
    }
}
 
export default Footer;