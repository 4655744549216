import React,{Component} from 'react';
import FacebookLogin from 'react-facebook-login';
import Avatar from 'react-avatar';

export default class Facebook extends Component {
  constructor(props) {
    super(props)
  this.state = {
    isLoggedIn: false,
    userID: "",
    name: "",
    email: "",
    picture: "",
    token:""
  };
}

  responseFacebook = response => {
    console.log(response);
        
          this.setState({
      isLoggedIn: true,
      userID: response.userID,
      name: response.name,
      email: response.email,
      picture: response.picture.data.url,
      token: response.accessToken
    });
        
   
    
  };

  componentClicked = () =>{console.log(this.props)};

  render() {
    let fbContent;

    if (this.state.isLoggedIn) {
      const graphqlQuery={
        query:`mutation register($email: String!, $username: String!, $password: String!) {
        register(input: {email: $email, username: $username, password: $password}) {
               user {id, username, email}, jwt
      }
      
        }`,variables: {        
          email: this.state.email,        
          password: this.state.token,        
          username: this.state.name      
        }
    };
      fetch(`${process.env.REACT_APP_BACKEND_URL}/graphql`,{
      method:'POST',
      headers:{
        'Content-Type':'application/json'
      },
      body:JSON.stringify(graphqlQuery)
      }).then(res=>{
        return res.json();
      })
      .then(res=>{
        if(res.errors && res.errors[0].status===422){
          throw new Error('user creation failed')
        }
        if(res.data==null && res.errors[0].extensions.exception.data.data[0].messages[0].id=='Auth.form.error.email.taken'){
          console.log('ajax')
          console.log(res.errors[0].extensions.exception.data.data[0].messages[0].id)
        }
      })






      fbContent = (
        <div className="profile-sidebar">
          <div className="profile-userpic">
             <Avatar size="50" round={true} src={this.state.picture} />
         </div>
          <div className="profile-usertitle">
           <div class="profile-usertitle-job">
            {this.state.name}
             </div>
          </div></div>
      );
    } else {
      fbContent = (
        <FacebookLogin
          appId="4776181475757848"
          autoLoad={false}
          fields="name,email,picture"
          onClick={this.componentClicked}
          callback={this.responseFacebook}
          cssClass='btnFacebook'
        />
      );
    }

    return fbContent;
  }
}
