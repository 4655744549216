import React, { Component } from "react";
import Slider from "react-slick";
// import "~slick-carousel/slick/slick.css"; 
// import "~slick-carousel/slick/slick-theme.css";
import { Image } from 'semantic-ui-react'
import Typography from '@material-ui/core/Typography';

export default class temp extends Component {

    constructor(props) {
      super(props);
      this.state = {  }
      
    }
    onClicKRecipes=(event)=>{
      {console.log(this.props.context)}
      const history = this.props.context.history
        if (history) history.push(`/recipe-infos/${event.target.dataset.value}`)
    }
   
 
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      focusOnSelect: false,
    };
    return (
      <div style={{ width: '100%', backgroundColor: '#eff4f7',cursor:'pointer' }}>

        <Slider {...settings}>

        {this.props.FeaturedContent!=null?this.props.FeaturedContent.recipe_infos.map((item,i)=> <div>
         
            <div class="thumbnail" key={i}>
              <div className='slide-overlay' onClick={this.onClicKRecipes} data-value={item.id}></div>
              <Image
                src={ `${process.env.REACT_APP_BACKEND_URL}`+item.Featuredimage.url}
                as='a'
                onClick={this.onClicKRecipes} data-value={item.id}
                className="sliderimage"
              />
              <div className="caption" onClick={this.onClicKRecipes} data-value={item.id}>
                <h1 className="RecipeTittle" onClick={this.onClicKRecipes} data-value={item.id}>
                  {item.Title}
                                </h1>
                {/* <time className="entry-date" datetime="2016-01-21T11:03:56+00:00" key={i}>January 21, 2016</time> */}
                <div className="slide_button"> <a onClick={this.onClicKRecipes} data-value={item.id}>Read More</a></div>
              </div>
            </div>
          </div>):""}



          {/* <div>
            <div class="thumbnail">
              <div className='slide-overlay'></div>
              <Image
                src='https://media.istockphoto.com/photos/bulgur-with-chicken-green-peas-and-carrot-on-wooden-background-picture-id1197427171'
                as='a'
                className="sliderimage"
              />
              <div class="caption">
                <h1 className="RecipeTittle">
                  Chicken Briyani
                                </h1>
                <time class="entry-date" datetime="2016-01-21T11:03:56+00:00">January 21, 2016</time>
              </div>
            </div>
          </div>
          <div>
            <div class="thumbnail">
              <div className='slide-overlay'></div>
              <Image
                src='https://media.istockphoto.com/photos/gujia-in-plate-with-holi-color-in-bowl-picture-id482704064'
                as='a'
                className="sliderimage"
              />
              <div class="caption">
              <h1 className="RecipeTittle">
                  Green Squash Curry (Suraikkai Kootu)
          </h1>
                <time class="entry-date" datetime="2016-01-21T11:03:56+00:00">January 21, 2016</time>
              </div>
            </div>
          </div> */}

        </Slider>
      </div>
    );
  }
}