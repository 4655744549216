import React from 'react';
import Header from './component/Navbar';
import StrapiProps from './component/StrapiProps';
import './App.css';
// import './assets/scss/style.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  BrowserRouter,

} from "react-router-dom";
import Post from './component/Post';
import GrandmaSays from './component/GranmaSays'
import GrandmaView from './component/GrandmaView'
import Homepage from './component/Homepage'
import Disclosure from './component/Disclosure';
import Footer from './component/Footer';
const Nofound = (props) => {
  return <div><h1 style={{textAlign:'center'}}>404</h1></div>
}

function App() {
  return (
    <>
      <Router>
         <Header />
      <Switch>
        <Route path='/' exact component={Homepage} />
        <Route path='/Grandma-Says' component={GrandmaSays} />
        <Route path='/GrandmaSays/:id' component={GrandmaView} />
        <Route path='/recipe-infos/:id' component={Post} />
        <Route path='/Disclosure' component={Disclosure} />
       
      <Route component={Nofound} /> 
      </Switch>
      <Footer/>
    </Router>
    </>
  );
}

export default App;
