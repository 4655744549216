import React, { Component } from 'react'

class ToggleText extends React.Component {
    constructor (props){
      super(props);
      this.state={
        readMore: false,
      }
    }
    
    handleReadMoreClick() {
      this.setState({ readMore: !this.state.readMore })
    }
    
    render(){
      return (
         
        <span key={this.props.id}>
             {console.log(this.props)}
      <div className={this.state.readMore ? "read-more" : "read-less"}><span>{this.props.Description}</span>{this.props.GranmaSays!=null?this.props.GranmaSays.map((step,i)=><p><span className='Readmore_text'>{i+1}.</span>{step.GramaIngredient}</p>):""}</div>
          <p onClick={() => { this.handleReadMoreClick(this.props.id) }}>
            <a className='Readmore_text'> { this.state.readMore ? 'Show less' : 'Continue reading' }</a   > 
          </p>
        </span>
      )
    }
  }
export default ToggleText;