import React, { Component } from 'react'
import RecipeLists from './RecipeLists'
import GranmaSays from './GranmaSays'
export default class StrapiProps extends Component {
    constructor(){
        super()
        this.state={
            products:[],
            imagePath: "http://sapaaduvms.eastus.cloudapp.azure.com:1337",
            PostId:0,
            SearchUrl:'http://sapaaduvms.eastus.cloudapp.azure.com:1337/recipe-infos',
            ContentTag:"/content-tags",
            GranmaSaysPath:'/grama-says',
            ContentItem:[],
            Topingredient:[]
        }
        
    }
    componentDidMount() {
       this.fetchData(this.state.SearchUrl,this.state.products)
       this.fetchData(this.state.imagePath+this.state.GranmaSaysPath,this.state.GranmaSays)
       
      
        Promise.all([
            fetch(this.state.SearchUrl).then(res => res.json()),
            fetch(this.state.imagePath+this.state.GranmaSaysPath).then(res => res.json())
        ]).then(result => {
            const [ rentalsData, driversData, vehiclesData ] = result;
          
            console.log('asd',result) 
          });
    }
    fetchData(url,currentItem){
        try {
            fetch(url)
                .then(function (response) {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    return response.json();
                })
                .then((res) => {
                    console.log(res)
                    this.setState({
                        currentItem: res
                    })
                });
        } catch (error) {
            alert("Something Went Wrong");
            console.log(error);
        }
    }
    
    render() {
        return (
            <div>
                {console.log('asd',this.state)}
                <RecipeLists context={this.props} Post={this.state}/>
                {/* <GranmaSays context={this.props} Post={this.state} /> */}
            </div>
        )
    }
}
