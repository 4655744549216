import React, { Component } from 'react';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import { Image } from 'semantic-ui-react'
export default class CustomSlider extends Component {
    OnclickImage=(event)=>{
        console.log('xc',this.props.context);
        const { history } = this.props.context;
        if (history) history.push(`/Grandma-Says`);
    }
  render() {
    // const settings =  {
       
        
    //   };
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true,
        
        previousButton: '',
        nextButton: '',
      };
    
    
    return (
      <div>
        <Slider autoplay={3000} { ...settings }>
	{/* {this.props.Ingredient.map((item, index) => (
		<div
			key={index}
			style={{ background: `url('${this.props.path+item.FeatureImage.formats.small.url}') no-repeat center center`,cursor:'pointer' }}
		 onClick={this.OnclickImage} data-value={item.IngredientName}>
			<div className="Ingredientcenter">
			</div>
    </div>
    


	))} */}
   {this.props.Ingredient.map((item, index) => (
    <div onClick={this.OnclickImage}> <Image
                src={this.props.path+item.FeatureImage.formats.small.url}
                as='a'
                size="large"
                className="Ingredientimage"
              /></div>
    


	))}
</Slider>
      </div>
    );
  }
}
// import React, { Component } from 'react';
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
// import { Image } from 'semantic-ui-react'
// const responsive = {
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 4,
//     slidesToSlide: 1 // optional, default to 1.
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 4,
//     slidesToSlide: 1 // optional, default to 1.
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 4,
//     slidesToSlide: 1 // optional, default to 1.
//   }
// };
// class Carousal extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {  }
//   }
//   OnclickImage=(event)=>{
//             console.log('xc',this.props.context);
//             const { history } = this.props.context;
//             if (history) history.push(`/Grandma-Says`);
//         }
//   render() { 
//     return (  <Carousel
//       swipeable={true}
//       draggable={true}
//       showDots={false}
//       arrows={false} 
//       fade={true}
//       responsive={responsive}
//       ssr={true} // means to render carousel on server-side.
//       infinite={true}
//       autoPlay={this.props.deviceType !== "mobile" ? true : false}
//       autoPlaySpeed={500}
//       // speed={8000}
//       keyBoardControl={true}
//       customTransition="all .5"
//       transitionDuration={500}
//       containerClass="carousel-container"
//       removeArrowOnDeviceType={["tablet", "mobile"]}
//       deviceType={this.props.deviceType}
//       dotListClass="custom-dot-list-style"
//       itemClass="carousel-item-padding-40-px"
//       cssEase='linear'
//       className="carousel_item"
//     >
//       {this.props.Ingredient.map((item, index) => (
//     <div onClick={this.OnclickImage}> <Image
//                 src={this.props.path+item.FeatureImage.formats.small.url}
//                 as='a'
//                 size="medium"
//                 className="Ingredientimage"
//               /></div>
    


// 	))}
//     </Carousel>);
//   }
// }
 
// export default Carousal;
