import React, { Component } from 'react'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Dropdown } from 'semantic-ui-react'
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Carousal from './Carousal'
import HeroSlider from './HeroSlider';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import HotelIcon from '@material-ui/icons/Hotel';
import RepeatIcon from '@material-ui/icons/Repeat';
import Paper from '@material-ui/core/Paper';
import GrandmaSayBackground from '../images/Grandmasays2.jpg'
import { Breadcrumb } from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown'
import { Segment,} from 'semantic-ui-react'

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%',
    },
    cardContent: {
        flexGrow: 1,
        cursor: 'pointer'
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    chip: {
        padding: "20px"
    },
    paper2: {
        padding: '3px 4px',
        textAlign: 'center',
        cursor: 'pointer'
    },
    secondaryTail: {
        backgroundColor: '#ff7e05',
    },
});
const options = []
class RecipeLists extends Component {
    constructor() {
        super()
        this.state = {
            CategoryParent: null,
            RecipeItem: [],
            Count: 1,
            Subcategory: [],
            SubcategoryFalg: 0,
            SubcategoryUrl: '?Subcategory_contains=',
            Join_url: '&Title_contains=',
            Url: '/recipe-infos?Title_contains=',
            CallbackURL: '/recipe-infos',
            options,
            DefaultRecipe: [],
            SearchFlag: 0,
            CategoryMode: 1,
            CategoryLabel: null,
            SubCategoryLabel: null
        }
    }
    handleAddition = (e, { value }) => {
        this.setState((prevState) => ({
            options: [{ text: value, value }, ...prevState.options],
        }))
    }
    handleChange = (e, { value }) => {
        this.setState({
            currentValues: value,
            SearchFlag: 1
        })
        let searchItem = '';
        console.log(value);
        if (value.length > 0) {
            value.forEach((element, i) => {
                if (i > 0) {
                    searchItem += this.state.Join_url + element
                } else {
                    searchItem += this.state.Url + element
                }
            });
        } else {
            searchItem = this.state.CallbackURL
        }
        console.log('bnm', this.props.Post.imagePath + searchItem)
        this.ReloadFetch(this.props.Post.imagePath + searchItem)
    }
    ReloadFetch(url) {
        let tempArray = [];
        let SubcategoryItem = [];
        let CheckExistingItem = [];
        try {
            fetch(url)
                .then(function (response) {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    return response.json();
                })
                .then((res) => {
                    if (this.state.SearchFlag == 1) {
                        console.log('search', res)
                        res.forEach(Recipe => {
                            tempArray.push(Recipe)
                        });
                    } else {
                        if (this.state.SubcategoryFalg) {
                            res.forEach(element => {
                                element.recipe_infos.forEach((val, j) => {
                                    console.log('res1', val);
                                    tempArray.push(val)
                                });
                            });
                        } else {
                            res.forEach((element, i) => {
                                console.log('res2', element.Subcategory)
                                SubcategoryItem.push(element)
                                element.recipe_infos.forEach((val, j) => {
                                    console.log('res21', val);
                                    if (!CheckExistingItem.includes(val.id)) {
                                        CheckExistingItem.push(val.id)
                                        tempArray.push(val)
                                    }
                                });
                            });
                            this.setState({
                                Subcategory: SubcategoryItem
                            })
                        }
                    }
                    this.setState({
                        RecipeItem: tempArray,
                    })
                    console.log('asdf', this.state.RecipeItem)
                    console.log('asdf', CheckExistingItem)
                });
        } catch (error) {
            alert("Something Went Wrong");
            console.log(error);
        }
    }
    NavigateToPost = (event) => {
        console.log(event.target.dataset.value)
        const { history } = this.props.context;
        if (history) history.push(`/recipe-infos/${event.target.dataset.value}`);
    }
    OnviewIngredient = (eventTrigger) => {
        const { history } = this.props.context;
        if (history) history.push(`/Grandma-Says`);
    }
    OnclickCategory = (e) => {
        console.log(e.currentTarget.dataset.value)
        this.setState({
            Count: 0,
            CategoryParent: e.currentTarget.dataset.value,
            SubcategoryFalg: 0,
            SearchFlag: 0,
            CategoryLabel: e.currentTarget.dataset.label,
            SubCategoryLabel: null
        })
        console.log(this.props.Post.imagePath + '/' + e.currentTarget.dataset.value)
        this.ReloadFetch(this.props.Post.imagePath + '/' + e.currentTarget.dataset.value)
    }
    OnclickSubCategory = (eve) => {
        this.setState({
            SubcategoryFalg: 1,
            SearchFlag: 0,
            SubCategoryLabel: eve.currentTarget.dataset.value
        })
        console.log(this.props.Post.imagePath + "/" + this.state.CategoryParent + this.state.SubcategoryUrl + eve.currentTarget.dataset.value)
        this.ReloadFetch(this.props.Post.imagePath + "/" + this.state.CategoryParent + this.state.SubcategoryUrl + eve.currentTarget.dataset.value + '&_limit=1');
    }
    render() {
        const { classes } = this.props;
        const { history } = this.props.context;
        const { currentValues } = this.state
        console.log('current', currentValues)
        let loadArray = []
        if (this.state.Count) {
            loadArray['Product'] = this.props.Post.products;
        } else {
            loadArray['Product'] = this.state.RecipeItem
            loadArray['Subcategory'] = this.state.Subcategory;
        }
        if (this.state.SearchFlag) {
            loadArray['Product'] = this.state.RecipeItem
        }
        console.log(loadArray)
        let itemList;
        let Category;
        let SubcategoryValue;
        if (loadArray['Product'] == undefined) {
            console.log("empty")
        } else {
            console.log("non-empty")
            console.log(loadArray)
            itemList = loadArray['Product'].map((product, i) => {
                return (<Grid item key={i} xs={12} sm={6} md={4} style={{ borderRadius: '0px' }}>
                    <Card className={classes.card} style={{ borderRadius: '0px' }} >
                        <CardMedia
                            className={classes.cardMedia, 'post_thumb_withbg'}
                            image={product.Featuredimage != null ? this.props.Post.imagePath + product.Featuredimage.url : "https://react.semantic-ui.com/images/wireframe/square-image.png"}
                            title={product.Title}
                        >
                            <a className='post_thumb' onClick={this.NavigateToPost} data-value={product.id}></a></CardMedia>
                        <CardContent className={classes.cardContent, 'ReceipeItem'} style={{ padding: '10px' }} >
                            <Typography gutterBottom variant="h5" component="h2" align='center' onClick={this.NavigateToPost} data-value={product.id} >
                                {product.Title}
                            </Typography>
                            {/* <Typography> */}
                            {/* {product.SmallDescription} */}
                            {/* </Typography>   */}
                        </CardContent>
                        {/* <CardActions> */}
                        {/* <Typography component="h5" variant="h5" align="center" color="textPrimary" gutterBottom>
            </Typography> */}
                        {/* <Grid item xs={12}> */}
                        {/* <Rating name={"half-rating"+i} defaultValue={2.5} precision={0.5} /> */}
                        {/* </Grid> */}
                        {/* </CardActions> */}
                    </Card>
                </Grid>)
            })
            if (loadArray['Subcategory'] != null) {
                SubcategoryValue = loadArray['Subcategory'].map((Content, i) => {
                    console.log(Content)
                    return (
                        <React.Fragment>
                            {/* <Chip
                            variant="outlined"
                            size="medium"
                            data-value={Content}
                            label={Content}
                            clickable
                           style={{margin:'10px'}}
                            onClick={this.OnclickSubCategory}
                        /> */}
                            {i % 2 == 0 ? <TimelineItem>
                                <TimelineOppositeContent>
                                    <Paper elevation={3} className={classes.paper2} data-value={Content.Subcategory} onClick={this.OnclickSubCategory}>
                                        <Typography variant="h6" component="h1" data-value={Content.Subcategory} onClick={this.OnclickSubCategory} className='with-overlay div-side smooth-offset-box-shadow ' style={{ background: `linear-gradient(rgba(0,0,0, 0.5), rgba(0,0,0,0.5)),url('${Content.Avatar != null ? this.props.Post.imagePath + Content.Avatar.url : "https://react.semantic-ui.com/images/wireframe/square-image.png"}') no-repeat center center`, color: "white" }}>
                                            {Content.Subcategory}
                                        </Typography>
                                    </Paper>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot style={{ margin: '2px', backgroundColor: 'white', borderStyle: 'none' }}>
                                        <Avatar src={Content.Avatar != null ? this.props.Post.imagePath + Content.Avatar.url : "https://react.semantic-ui.com/images/wireframe/square-image.png"} style={{ width: '20px', height: '20px' }}  ></Avatar>
                                    </TimelineDot>
                                    {loadArray['Subcategory'].length - 1 != i ? <TimelineConnector className={classes.secondaryTail} /> : ""}
                                </TimelineSeparator>
                                <TimelineContent>
                                </TimelineContent>
                            </TimelineItem> : <TimelineItem>
                                    <TimelineOppositeContent>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot style={{ margin: '2px', backgroundColor: 'white', borderStyle: 'none' }}>
                                            <Avatar src={Content.Avatar != null ? this.props.Post.imagePath + Content.Avatar.url : "https://react.semantic-ui.com/images/wireframe/square-image.png"} style={{ width: '20px', height: '20px', }} ></Avatar>
                                        </TimelineDot>
                                        {loadArray['Subcategory'].length - 1 != i ? <TimelineConnector className={classes.secondaryTail} /> : ""}
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Paper elevation={3} className={classes.paper2} data-value={Content.Subcategory} onClick={this.OnclickSubCategory}>
                                            <Typography variant="h6" component="h1" data-value={Content.Subcategory} onClick={this.OnclickSubCategory} className='with-overlay div-side smooth-offset-box-shadow ' style={{ background: `linear-gradient(rgba(0,0,0, 0.5), rgba(0,0,0,0.5)),url('${Content.Avatar != null ? this.props.Post.imagePath + Content.Avatar.url : "https://react.semantic-ui.com/images/wireframe/square-image.png"}') no-repeat center center`, color: "white" }}>
                                                {Content.Subcategory}
                                            </Typography>
                                        </Paper>
                                    </TimelineContent>
                                </TimelineItem>}
                        </React.Fragment>
                    )
                });
            }
        }
        console.log(this.props);
        if (this.props.Post.ContentItem != undefined) {
            Category = this.props.Post.ContentItem.map((Content, i) => {
                console.log(Content.Avator)
                return (
                    <Chip
                        variant="outlined"
                        size="medium"
                        avatar={<Avatar src={Content.Avator != null ? this.props.Post.imagePath + Content.Avator.url : ""} />}
                        label={Content.Category}
                        value={Content.Category.toLowerCase() + 's'}
                        data-value={Content.Category.toLowerCase() + 's'}
                        data-label={Content.Category}
                        clickable
                        style={{ margin: '10px', fontSize: '13px', fontWeight: '600' }}
                        onClick={this.OnclickCategory}
                    />
                )
            });
        } else {
            console.log("empty")
        }
        // let InfiniteArray=[];
        // for(var i=0;i<4;i++){
        //     if(this.props.Post.Topingredient!=null)
        //     this.props.Post.Topingredient.forEach(element => {
        //         InfiniteArray.push(element)
        //     });
        // }
       
       
            
        return (
            <React.Fragment>
                <main>
                    <Container style={{ paddingBottom: '20px' }}>
                        <HeroSlider FeaturedContent={this.props.Post.MostFeaturedContent != null ? this.props.Post.MostFeaturedContent[0] : null} context={this.props.context} />
                    </Container>
                    <Container style={{ paddingTop: '20px' }}>
                        <Container className={classes.cardGrid} maxWidth="lg" style={{ padding:'0' }}>
                            <Grid item xs={12} className='DividerCommon'>
                                <Grid container spacing={3} className='DividerCommon'>
                                    <Grid item xs={12} sm={12} className='DividerCommon main' >
                                        <div class="hero">
                                            <h1>Grandma Says</h1>
                                            <p style={{ fontSize: '1.33em', textAlign: 'center', lineHeight: '2.5' }} className="main-heading-secondary ">"Grandma Says" is our new space on <a  className='CommonColor'> SAPAADU.COM </a> ,dedicated to all the wise "foody" advice received from our parents, grandparents, great-grandparents, and all our ancestors. </p>
                                            <div className="grandmaslide_button"> <a href="/Grandma-Says">Read More</a></div>
                                        </div>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={12} className="sliderControl" > */}
                                    {/* <Carousal Ingredient={this.props.Post.Topingredient} path={this.props.Post.imagePath} context={this.props.context} /> */}
                                    {/* </Grid> */}
                                </Grid>
                            </Grid>
                        </Container>
                    </Container>
                    <Container className={classes.cardGrid,'CustompaddingSlider'} maxWidth="lg" >
                        <Grid container >
                            <logo-slider onClick={()=>history.push(`/Grandma-Says`)}>
                                <div>
                                    {this.props.Post.Topingredient != null ? this.props.Post.Topingredient.map((PerItem, M) => <React.Fragment><div className="Slidecontainer"><img src={this.props.Post.imagePath + PerItem.FeatureImage.url} alt={PerItem.IngredientName} /><span className='centeredText'>{PerItem.IngredientName}</span></div></React.Fragment>) : ""}
                                </div>
                                <div>
                                    {this.props.Post.Topingredient != null ? this.props.Post.Topingredient.map((PerItem, M) => <React.Fragment><div className="Slidecontainer"><img src={this.props.Post.imagePath + PerItem.FeatureImage.url} alt={PerItem.IngredientName} /><span className='centeredText'>{PerItem.IngredientName}</span></div></React.Fragment>) : ""}
                                </div>
                                <div>
                                    {this.props.Post.Topingredient != null ? this.props.Post.Topingredient.map((PerItem, M) => <React.Fragment><div className="Slidecontainer"><img src={this.props.Post.imagePath + PerItem.FeatureImage.url} alt={PerItem.IngredientName} /><span className='centeredText'>{PerItem.IngredientName}</span></div></React.Fragment>) : ""}
                                </div>
                            </logo-slider>
                        </Grid>
                    </Container>
                    <Container className={classes.cardGrid} maxWidth="md" style={{paddingTop:'0'}}>
                       
                        <Grid item xs={12}>
                        <div id="211085901"></div>
                            <Typography component="h5" variant="h5" align="left" color="textPrimary" gutterBottom>
                                Search Recipes
            </Typography>
                            <Dropdown
                                options={this.state.options}
                                placeholder='Briyani,Curd Rice,etc....'
                                search
                                selection
                                fluid
                                multiple
                                allowAdditions
                                value={currentValues}
                                onAddItem={this.handleAddition}
                                onChange={this.handleChange}
                                className='CustomSearch'
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <Grid container spacing={3}>
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    {/* <Typography component="h6" variant="h6" align="center" color="textPrimary" gutterBottom>
                                        CATEGORY
            </Typography> */}
                                    <h2 style={{ textAlign: 'center', width: '100%', marginTop: "20px" }}>Category</h2>
                                    {Category}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                </Grid>
                                <Grid item xs={12} sm={6} style={{ paddingBottom: '20px' }}>
                                    {/* <Typography component="h6" variant="h6" align="center" color="textPrimary" gutterBottom>
                                        TAGS
            </Typography> */}
                                    <h2 style={{ textAlign: 'center', width: '100%', marginTop: "20px" }}>{this.state.CategoryLabel}</h2>
                                    {SubcategoryValue}
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container className={classes.cardGrid} maxWidth="lg" style={{ paddingTop: '2px' }}>
                        <Grid xs={12} sm={12} style={{ marginBottom: "30px" }}>
                            <Breadcrumb>
                                <Breadcrumb.Section link><a href='/'>Home</a></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right angle' />
                                <Breadcrumb.Section link><a onClick={this.OnclickCategory} data-value={this.state.CategoryParent} data-label={this.state.CategoryLabel}>{this.state.CategoryLabel}</a></Breadcrumb.Section>
                                {this.state.SubCategoryLabel != null ? <React.Fragment><Breadcrumb.Divider icon='right angle' />
                                    <Breadcrumb.Section link><a onClick={this.OnclickSubCategory} data-value={this.state.SubCategoryLabel}>{this.state.SubCategoryLabel}</a></Breadcrumb.Section></React.Fragment> : ""}
                            </Breadcrumb>
                        </Grid>
                        <Grid container spacing={5}>
                            {itemList}
                        </Grid>
                        <Grid item xs={12} >
                        </Grid>
                    </Container>
                </main>
            </React.Fragment>
        );
    }
}
export default withStyles(styles, { withTheme: true })(RecipeLists);