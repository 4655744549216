import React, { Component } from 'react'
import RecipeLists from './RecipeLists'
export default class Homepage extends Component {
    constructor() {
        super()
        this.state = {
            products: [],
            imagePath: `${process.env.REACT_APP_BACKEND_URL}`,
            PostId: 0,
            SearchUrl: `${process.env.REACT_APP_BACKEND_URL}/recipe-infos`,
            ContentTag: "/content-tags",
            ContentItem: [],
            GranmaSaysPath: '/grama-says',
            Topingredient: [],
            Outercontenturl:'/outer-contents',
            OuterContent:[],
            MostFeaturedUrl:'/most-featured-recipes',
            MostFeaturedContent:[]

        }

    }
    componentDidMount() {
        Promise.all([
            fetch(this.state.imagePath + this.state.ContentTag).then(res => res.json()),
            fetch(this.state.SearchUrl).then(res => res.json()),
            fetch(this.state.imagePath + this.state.GranmaSaysPath).then(res => res.json()),
            fetch(this.state.imagePath + this.state.Outercontenturl).then(res => res.json()),
            fetch(this.state.imagePath + this.state.MostFeaturedUrl).then(res => res.json()),
        ]).then(result => {
            this.setState({
                ContentItem: result[0],
                products: result[1],
                Topingredient: result[2],
                OuterContent: result[3],
                MostFeaturedContent: result[4],
            })
        });
        try { window._mNHandle.queue.push(function (){ window._mNDetails.loadTag("864068883", "970x250", "864068883"); }) } catch (error) {} 
        try { window._mNHandle.queue.push(function (){ window._mNDetails.loadTag("211085901", "970x90", "211085901"); }); } catch (error) {}
        try { window._mNHandle.queue.push(function (){ window._mNDetails.loadTag("575518156", "728x90", "575518156"); }); } catch (error) {}
        try { window._mNHandle.queue.push(function (){ window._mNDetails.loadTag("199144025", "320x50", "199144025"); }); } catch (error) {} 
        try { window._mNHandle.queue.push(function (){ window._mNDetails.loadTag("108569473", "300x600", "108569473"); }); } catch (error) {} 
        try { window._mNHandle.queue.push(function (){ window._mNDetails.loadTag("759031059", "300x50", "759031059"); }); } catch (error) {} 
        try { window._mNHandle.queue.push(function (){ window._mNDetails.loadTag("828944828", "160x600", "828944828"); }); } catch (error) {} 

    }


    render() {
        return (
            <div>
                <RecipeLists context={this.props} Post={this.state} />

            </div>
        )
    }
}
