import React, { Component } from 'react'
import { Button, Container, Grid, Header, Image, Item, Menu, Segment, Step, Table } from 'semantic-ui-react'
import { Breadcrumb } from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown'
const style = {
    h1: {
        marginTop: '3em',
    },
    h2: {
        margin: '4em 0em 2em',
    },
    h3: {
        marginTop: '2em',
        padding: '2em 0em',
    },
    last: {
        marginBottom: '300px',
    },
}
function LinkRenderer(props) {
    return <a href={props.href} rel="noopener" target="_blank">{props.children}</a>
  }
class GrandView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PathUrl: `${process.env.REACT_APP_BACKEND_URL}`,
            SearchUrl: 'grama-says?IngredientName_eq=',
            GrandmaItem: []
        }

    }
    componentDidMount() {
        console.log('asd', this.state.PathUrl + '/' + this.state.SearchUrl + this.props.match.params.id)
        this.OnloadData(this.state.PathUrl + '/' + this.state.SearchUrl + this.props.match.params.id)
        try { window._mNHandle.queue.push(function (){ window._mNDetails.loadTag("864068883", "970x250", "864068883"); }) } catch (error) {} 
        try { window._mNHandle.queue.push(function (){ window._mNDetails.loadTag("211085901", "970x90", "211085901"); }); } catch (error) {}
        try { window._mNHandle.queue.push(function (){ window._mNDetails.loadTag("575518156", "728x90", "575518156"); }); } catch (error) {}
        try { window._mNHandle.queue.push(function (){ window._mNDetails.loadTag("199144025", "320x50", "199144025"); }); } catch (error) {} 
        try { window._mNHandle.queue.push(function (){ window._mNDetails.loadTag("108569473", "300x600", "108569473"); }); } catch (error) {} 
        try { window._mNHandle.queue.push(function (){ window._mNDetails.loadTag("759031059", "300x50", "759031059"); }); } catch (error) {} 
        try { window._mNHandle.queue.push(function (){ window._mNDetails.loadTag("828944828", "160x600", "828944828"); }); } catch (error) {} 
    }
    OnloadData(url) {
        try {
            fetch(url)
                .then(function (response) {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    return response.json();
                })
                .then((res) => {
                    console.log(res)
                    this.setState({
                        GrandmaItem: res[0]
                    })
                });
        } catch (error) {
            alert("Something Went Wrong");
            console.log(error);
        }
    }
    
    render() {
        console.log(this.state.GrandmaItem)
        return (<React.Fragment>
            <main>
                <Container  maxWidth="md">
                    
            <Grid container stackable verticalAlign='middle'>
                <Breadcrumb>
                    <Breadcrumb.Section link><a href='/'>Home</a></Breadcrumb.Section>
                    <Breadcrumb.Divider icon='right angle' />
                    <Breadcrumb.Section link><a href='/Grandma-Says'>GrandmaSays</a></Breadcrumb.Section>
                    <Breadcrumb.Divider icon='right angle' />
                    <Breadcrumb.Section link>{this.props.match.params.id}</Breadcrumb.Section>
                </Breadcrumb>
                <h2 style={{textAlign:'center',width:'100%',marginTop:"20px"}}>{this.props.match.params.id}</h2>
                <Grid.Row>
                            <Grid.Column floated='right' width={8} >
                                <Image bordered rounded size='large' src={this.state.GrandmaItem.FeatureImage != null ? this.state.PathUrl + this.state.GrandmaItem.FeatureImage.url : "https://react.semantic-ui.com/images/wireframe/square-image.png"} />
                            </Grid.Column>
                            <Grid.Column width={8} >
                                <Header as='h3' style={{ fontSize: '2em' }}>

                                </Header>
                                <p style={{ fontSize: '1.33em' }}>
                                <ReactMarkdown source={this.state.GrandmaItem.Description} escapeHtml={false}  renderers={{link: props => <a  target="_blank" href={props.href}> {props.children}</a >}}/>
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                        
                        <Grid.Row>
                            <Grid.Column>
        {this.state.GrandmaItem.GrandmaSays!=null? this.state.GrandmaItem.GrandmaSays.map((item)=><p style={{ fontSize: '1.20em' }}><ReactMarkdown source={item.GrandmaIngredient} escapeHtml={false} renderers={{link: props => <a href={props.href} target="_blank">{props.children}</a>}}/></p>):""}
                            {/* <p style={{ fontSize: '1.33em' }}>
                                    {this.state.GrandmaItem.Description}
                                </p>
                                <p style={{ fontSize: '1.33em' }}>
                                    {this.state.GrandmaItem.Description}
                                </p> */}
                                </Grid.Column>
                                </Grid.Row>
            </Grid> <div id="864068883"></div></Container></main></React.Fragment>);
    }
}

export default GrandView;











