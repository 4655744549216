import React, { PureComponent } from 'react'
import { Table } from 'reactstrap';
import { Button, Grid, Header, Icon, Image, Item, Label, Menu, Segment, Step, Container } from 'semantic-ui-react'
import { Breadcrumb } from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown'
const style = {
    h1: {
        marginTop: '3em',
    },
    h2: {
        margin: '4em 0em 2em',
    },
    h3: {
        marginTop: '2em',
        padding: '2em 0em',
        textAlign: "center"
    },
    last: {
        marginBottom: '300px',
    },
}
export default class Post extends PureComponent {
    constructor() {
        super()
        this.state = {
            PostItem: [],
            PostPath: `${process.env.REACT_APP_BACKEND_URL}`,
            Ingredients: [],
            Serving: 0,
            ImageSource: [],
            StepsExplanation: [],
            StepsImage: [],
            Orignal_Ingredients: [],
            PreparationStep: [],
            author: [],
            Tips:[]
        }
        this.ServingOnchange = this.ServingOnchange.bind(this)
    }
    componentDidMount() {
        try {
            fetch(this.state.PostPath + this.props.match.url)
                .then(function (response) {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    return response.json();
                })
                .then((res) => {
                    console.log(res)
                    let ImageTemp;

                    if (res.CookingSteps == null) {
                        console.log("null")
                        ImageTemp = null
                    } else {
                        console.log("Not null")
                        ImageTemp = res.CookingSteps
                    }
                    this.setState({
                        PostItem: res,
                        Ingredients: res.Ingredient,
                        Orignal_Ingredients: res.Ingredient,
                        Serving: res.Serving,
                        ImageSource: res.Featuredimage,
                        PreparationStep: res.PreparationSteps,
                        // StepsExplanation:res.StepsExplanation,
                        author: res.authors.length > 0 ? res.authors : [],
                        Tips:res.Tips.length > 0 ? res.Tips : [],
                        StepsImage: ImageTemp
                    })
                });
        } catch (error) {
            alert("Something Went Wrong");
            console.log(error);
        }
        try { window._mNHandle.queue.push(function () { window._mNDetails.loadTag("864068883", "970x250", "864068883"); }) } catch (error) { }
        try { window._mNHandle.queue.push(function () { window._mNDetails.loadTag("211085901", "970x90", "211085901"); }); } catch (error) { }
        try { window._mNHandle.queue.push(function () { window._mNDetails.loadTag("575518156", "728x90", "575518156"); }); } catch (error) { }
        try { window._mNHandle.queue.push(function () { window._mNDetails.loadTag("199144025", "320x50", "199144025"); }); } catch (error) { }
        try { window._mNHandle.queue.push(function () { window._mNDetails.loadTag("108569473", "300x600", "108569473"); }); } catch (error) { }
        try { window._mNHandle.queue.push(function () { window._mNDetails.loadTag("759031059", "300x50", "759031059"); }); } catch (error) { }
        try { window._mNHandle.queue.push(function () { window._mNDetails.loadTag("828944828", "160x600", "828944828"); }); } catch (error) { }
    }
    ServingOnchange(e) {
        let CurrentValue = e.target.value
        this.setState(function (prevState) {
            const initialArray = [];
            this.state.Orignal_Ingredients.forEach((product) => {
                let obj = {};
                let Ingredient_Cal = (CurrentValue / this.state.PostItem.Serving)
                console.log(CurrentValue / this.state.PostItem.Serving)
                console.log(Ingredient_Cal)
                let Metric_Result = this.MetricConvert(product.Unit, product.Quantity, Ingredient_Cal)
                // if(Metric_Result<16){
                // }else if(Metric_Result<17.637){
                // }else if(Metric_Result<17.637){
                // }
                // else if(Metric_Result<17.637){
                // }
                // else if(Metric_Result<17.637){
                // }
                if (CurrentValue >= prevState.Serving) {
                    obj = { Type: product.Type, Quantity: (product.Quantity * Ingredient_Cal).toFixed(2), Unit: product.Unit };
                } else {
                    // alert("10");
                    obj = { Type: product.Type, Quantity: (product.Quantity * Ingredient_Cal).toFixed(2), Unit: product.Unit };
                }
                initialArray.push(obj)
            })
            return {
                Serving: CurrentValue,
                Ingredients: initialArray
            };
        });
    }
    // MetricConvert = param => {
    // }
    MetricConvert(Unit, Quantity, IngCal) {
        let return_item;
        switch (Unit) {
            case 'Ounce':
                return_item = (Quantity * IngCal);
                return return_item;
            case 'Cups':
                return_item = ((Quantity * IngCal) * 8.000);
                return return_item;
            case 'Pound':
                return_item = ((Quantity * IngCal) * 16)
                return return_item;
            case 'Gram':
                return_item = ((Quantity * IngCal) * 0.035274)
                return return_item;
            case 'Kilogram':
                return_item = ((Quantity * IngCal) * 35.724)
                return return_item;
            default:
                return 'You are a User';
        }
    }
    render() {
        let Activepath = this.state.PostPath;
        let IngredientItem = this.state.Ingredients.map((items, i) => {
            return (<Grid.Row key={i}><Grid.Column>{items.Type}</Grid.Column><Grid.Column>{items.Quantity}</Grid.Column><Grid.Column>{items.Unit}</Grid.Column></Grid.Row>)
        });
        let prepationTime = this.state.PostItem.PrepTime != undefined ? this.state.PostItem.PrepTime.split(":", 2) : "";
        let CookingTime = this.state.PostItem.CookTime != undefined ? this.state.PostItem.CookTime.split(":", 2) : "";
        console.log(parseInt(prepationTime[0], 10))

        let Author = this.state.author.length > 0 ? <React.Fragment><Container>
            <Item.Group divided>
                <Item>
                    <Item.Image src={this.state.PostPath + this.state.author[0].Image[0].url} size='small' circular />
                    <Item.Content>
                        <Item.Header as='a'>{this.state.author[0].Name}</Item.Header>
                        <Item.Meta>
                            <span>Author of Recipe</span>
                        </Item.Meta>
                        <Item.Description>
                        <ReactMarkdown source= {this.state.author[0].Biography} escapeHtml={false} renderers={{link: props => <a href={props.href} target="_blank">{props.children}</a>}} />
                           
                        </Item.Description>

                    </Item.Content>
                </Item>
            </Item.Group>
        </Container></React.Fragment> : ""
        console.log('curryPos', this.state.author)
        console.log('curry', this.state.author)
        // let PreprationStep=this.state


        // let Steps = this.state.StepsImage.Stepsimage.map((step) => <li>{step}</li>)
        // console.log(this.state.StepsImage.Stepsimage)
        return (
            <div>

                <Segment style={{ padding: '8em 0em' }} vertical>

                    <Grid container stackable verticalAlign='middle'>
                        <Breadcrumb>
                            <Breadcrumb.Section link><a href='/'>Home</a></Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right angle' />
                            <Breadcrumb.Section link>{this.state.PostItem.Title}</Breadcrumb.Section>


                        </Breadcrumb>
                        <Grid.Row>
                            <Grid.Column width={12}>
                                {Author}
                                {/* {ShowAuthor} */}

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column floated='right' width={8}>
                                <Container ><Segment.Group><Segment> <Image bordered rounded size='big' src={this.state.PostItem.Featuredimage != null ? this.state.PostPath + this.state.PostItem.Featuredimage.url : "https://react.semantic-ui.com/images/wireframe/square-image.png"} /></Segment></Segment.Group></Container>
                                {/* <h1>author:{this.state.PostItem.users!=null?this.state.PostItem.users[0].username:""}</h1> */}

                            </Grid.Column>

                            <Grid.Column width={8} >
                                <Container className='NewcontainerDesign'><Segment.Group><Segment>
                                    <Header as='h3' style={{ fontSize: '2em' }}>
                                        {this.state.PostItem.Title}
                                    </Header>

                                    <p style={{ fontSize: '1.33em' }}>
                                        <ReactMarkdown source={this.state.PostItem.Description} escapeHtml={false} renderers={{link: props => <a href={props.href} target="_blank">{props.children}</a>}} />

                                    </p>
                                </Segment></Segment.Group></Container>

                            </Grid.Column>

                        </Grid.Row>
                        <Grid.Row>

                            <Grid.Column textAlign='center' >
                                <Container >
                                    <Segment.Group >
                                        <Segment >
                                            <Container >


                                                {/* <Grid columns='equal'> */}
                                                <Grid columns={3} stackable>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <Header attached='top' as='h4' inverted>
                                                                Serving
            </Header>
                                                            <Segment attached='bottom'><input
                                                                type="number"
                                                                min='0'
                                                                step='0'
                                                                className="ServeInput"
                                                                value={this.state.Serving}
                                                                onChange={this.ServingOnchange}
                                                            /></Segment>
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                            <Header attached='top' as='h4' inverted>
                                                                Prep Time
            </Header>
                                                            <Segment attached='bottom'>{parseInt(prepationTime[0], 10) + 'hr' + " " + prepationTime[1] + "min"}</Segment>
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                            <Header attached='top' as='h4' inverted>
                                                                Cook Time
            </Header>
                                                            <Segment attached='bottom'>{parseInt(prepationTime[0], 10) + 'hr' + " " + CookingTime[1] + "min"}</Segment>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                                <h3 style={{ textTransform: 'uppercase' }}>Ingredients</h3>
                                                <Segment.Group >
                                                    <Segment >
                                                        <Grid columns='equal'>
                                                            <Grid.Row>
                                                                <Grid.Column style={{ fontSize: '1.33em' }}>Type</Grid.Column>
                                                                <Grid.Column style={{ fontSize: '1.33em' }}>Quantity</Grid.Column>
                                                                <Grid.Column style={{ fontSize: '1.33em' }}>Unit</Grid.Column>
                                                            </Grid.Row>
                                                            {IngredientItem}
                                                        </Grid>
                                                    </Segment>
                                                </Segment.Group>
                                            </Container>

                                        </Segment>
                                    </Segment.Group>
                                </Container>
                            </Grid.Column>
                        </Grid.Row>

                        {/* prepation steps */}
                        {console.log('cvb', this.state.PostItem)}
                        <h3 style={{ textTransform: 'uppercase', width: '100%', textAlign: 'center' }}>Preparation Steps</h3>
                        {this.state.PreparationStep.length > 0 ? this.state.PreparationStep.map(function (Explain, i) {

                            return <React.Fragment>

                                <Grid.Row sm={12} xs={12} key={i} className="StepPadding">
                                    <Container className="NewcontainerDesign">
                                        <Segment.Group>
                                            <Segment> <span style={{ fontSize: '16px', fontWeight: '600' }}>Step&nbsp;{i + 1}</span></Segment>

                                            <Segment> <p style={{ fontSize: '16px' }}>  <ReactMarkdown source={Explain.Explanation} escapeHtml={false} renderers={{link: props => <a href={props.href} target="_blank">{props.children}</a>}} /></p></Segment>
                                            {Explain.Image != null ? <Segment>  <Grid verticalAlign='middle' columns={2} centered>

                                                <Image src={Activepath + Explain.Image.url} style={{ marginTop: '1.33em', marginBottom: '1.33em' }} size='large' />


                                            </Grid></Segment> : ""}
                                        </Segment.Group>
                                    </Container>
                                </Grid.Row>

                            </React.Fragment>
                        }) : ""}



                        {/* {this.state.StepsImage.map((Explain, i) => <Grid.Row columns={1}> <Grid.Column ><label>Step:{i}.</label>{Explain.Explanation} </Grid.Column></Grid.Row>{Explain.Image != null ?<Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'> <Grid.Row><Grid.Column style={{ maxWidth: 450 }} ><Image bordered rounded size='large' src={this.state.PostPath + Explain.Image.url} style={style} /></Grid.Column></Grid.Row></Grid> : ""})} */}
                        <h3 style={{ textTransform: 'uppercase', width: '100%', textAlign: 'center' }}>Cooking Steps</h3>
                        {this.state.StepsImage.map(function (Explain, i) {

                            return <React.Fragment>
                                <Grid.Row sm={12} xs={12} key={i} className="StepPadding">
                                    <Container className="NewcontainerDesign">
                                        <Segment.Group>
                                            <Segment style={{ paddingBottom: '0' }}> <span style={{ fontSize: '16px', fontWeight: '600' }}>Step&nbsp;{i + 1}</span></Segment>
                                            <Segment> <p style={{ fontSize: '16px' }}>  <ReactMarkdown source={Explain.Explanation} escapeHtml={false} renderers={{link: props => <a href={props.href} target="_blank">{props.children}</a>}} /></p></Segment>
                                            {Explain.Image != null ? <Segment><Grid verticalAlign='middle' columns={2} centered>

                                                <Image src={Activepath + Explain.Image.url} style={{ marginTop: '1.33em', marginBottom: '1.33em' }} size='large' />

                                            </Grid></Segment> : ""}
                                        </Segment.Group>
                                    </Container>
                                </Grid.Row>
                            </React.Fragment>
                        })}


                        <h3 style={{ textTransform: 'uppercase', width: '100%', textAlign: 'center' }}>Tips</h3>

                        
                        {this.state.Tips.length>0 ?this.state.Tips.map( function(items, i) {
console.log(items)
                            return <React.Fragment>
                                <Grid.Row sm={12} xs={12} key={i} className="StepPadding">
                                    <Container className="NewcontainerDesign">
                                        <Segment.Group >
                                            {/* <Segment style={{ paddingBottom: '0' }}> </Segment> */}
                                            <Segment style={{ display:'flex'}}><span style={{ fontSize: '16px', fontWeight: '600' }}>&nbsp;{i + 1}.</span> <p style={{ fontSize: '16px', paddingLeft:'10px' }}>  <ReactMarkdown source={items.Tips} escapeHtml={false}  renderers={{link: props => <a href={props.href} target="_blank">{props.children}</a>}}/></p></Segment>
                                        </Segment.Group>
                                    </Container>
                                </Grid.Row>
                            </React.Fragment>
                        }):""}
                    </Grid>

                </Segment>
                <div id="864068883"></div>
            </div>
        )
    }
}
