import React, { useState ,useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Dropdown from './Dropdown';
import FacebookLogin from './Facebook';
import Avatar from 'react-avatar';
import axios from 'axios';
import { useHistory } from "react-router-dom";


function Navbar(props) {
  const [click, setClick] = useState(false);
  let history = useHistory();
  console.log(history)
  // history.push('http://localhost:1337/connect/facebook')
 
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);


  const onMouseEnter = () => {
    console.log(window.innerWidth);
    // if (window.innerWidth < 960) {
    //   setDropdown(false);
    // } else {
      setDropdown(true);
    // }
  };

  const onMouseLeave = () => {
    // if (window.innerWidth < 960) {
      setDropdown(false);
    // } else {
    //   setDropdown(false);
    // }
  };

  
  
  
  return (
    <>
    <h1 className="LogoStyle">Sapaadu</h1>
                    <h2 className="Logobelow">Food is the best medicine</h2>
      <nav className='navbar'>

        {/* <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
          EPIC
          <i class='fab fa-firstdraft' />
        </Link> */}
        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
              Homepage
            </Link>
          </li>
          {/* <li
            className='nav-item'
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <Link
              to='/services'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Recipes <i className='fas fa-caret-down' />
            </Link>
            {console.log(dropdown)}
            {dropdown && <Dropdown />}
          </li> */}
          <li className='nav-item'>
            <Link
              to='/Grandma-Says'
              className='nav-links'
              onClick={closeMobileMenu}
            >
             Grandma Says
            </Link>
          </li>
          {/* <li className='nav-item'>
            <Link
              to='/contact-us'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Contact Us
            </Link>
          </li> */}
          {/* <li>
            <Link
              to='/sign-up'
              className='nav-links-mobile'
              onClick={closeMobileMenu}
            >
              Sign Up
            </Link>
          </li> */}
        </ul>
    {/* <FacebookLogin/> */}
    {console.log(props)}

      </nav>
    </>
  );
} 

export default Navbar;
